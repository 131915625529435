import type { MainSolutionOptionsObject } from "../../translation-keys/main-solution-option-keys.js";

const MainSolutions: MainSolutionOptionsObject = {
  "Quality": "Qualité",
  "Manufacturing": "Industrie manufacturière",
  "Logbooks": "Logbooks",
  "Clinical": "Clinical Excellence",
  "Product Development": "Développement du produit",
  "Regulatory": "Règlementaire",
  "Supplier": "Fournisseurs",
  "Post-Market": "Post-commercialisation"
};

export default MainSolutions;
